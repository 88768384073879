import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ContactUsForm from "../../components/contact-us-form"
import profilePhoto from "../../images/frank-russo.jpg"
import PageHeader from "../../components/page-header"
import { Row, Col } from "react-bootstrap"

export default () => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Frank Russo | RWK Attorneys" defer={false}>
			<script type="application/ld+json">{JSON.stringify({
				"@context": "http://schema.org/",
				"@type": "Person",
				"name": `Frank Russo`,
				"jobTitle": "Attorney",
				"telephone": "12058332589",
				"url": "https://rwkattorneys.com/attorneys/frank-russo"
			})}</script>
		</Helmet>

		<PageHeader>
			<Row>
				<Col>
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item"><Link to="/">Home</Link></li>
							<li class="breadcrumb-item"><Link to="/attorneys">Attorneys</Link></li>
							<li class="breadcrumb-item active" aria-current="page">Frank Russo</li>
						</ol>
					</nav>
					<h1>Frank Russo</h1>
					<p className="lead text-muted">Partner (ret.)</p>
				</Col>
			</Row>
		</PageHeader>

		<div className="container mt-3">

			<div className="row">
				<div className="col-md-3">
					<img src={profilePhoto} className="img-fluid" />
					<h1>Frank J. Russo</h1>
					<p className="lead">Partner (ret.)</p>

				</div>
				<div className="col-md-6">

					<p>Frank was the senior partner partner at <strong>Russo, White &amp; Keller, P.C.</strong> and retired from the practice of law in 2019. &nbsp;Frank graduated from Huntingdon College in Montgomery, Alabama in &nbsp;1974.</p>
					<p>After graduating, Frank secured a job in Iran and began teaching English as a foreign language. &nbsp;In 1979, Frank attended Miles College School of Law, graduating Cum Laude in 1982. Prior to forming Russo, White &amp; Keller, P.C., Frank operated his own firm for approximately 15 years. &nbsp;Frank is married to Caron Cornelius Russo.</p>
					
				</div>

				<div className="col">
					<ContactUsForm />
				</div>
			</div>
		</div>
	</Layout >
)